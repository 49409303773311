<template>
  <div class="reveal">
    <div class="slides">
      <Base />
      <Mesydel />
      <Casus />
      <Questionnaire />
      <Analysis />
      <Screenshots />
    </div>
  </div>
</template>

<script>
import Reveal from "reveal.js/js/reveal";

export default {
  name: "App",

  mounted() {
    Reveal.initialize({
      // Number of milliseconds between automatically proceeding to the
      // next slide, disabled when set to 0, this value can be overwritten
      // by using a data-autoslide attribute on your slides
      // autoSlide: 4000,
      // Loop the presentation
      // loop: true,

      // Stop auto-sliding after user input
      autoSlideStoppable: true,

      // Display the page number of the current slide
      slideNumber: true,

      // Push each slide change to the browser history
      history: true,
    });
  },
};
</script>

<style>
@import url("../../node_modules/reveal.js/css/reveal.css");
@import url("../assets/css/reveal-mesydel.css");

#app {
  height: 100vh;
}
</style>
