import Vue from 'vue';
import VueRouter from 'vue-router';
import Base from "@/views/Base.vue";
import Empty from "@/views/Empty.vue";

Vue.use(VueRouter);

var base_title = "Mesylab Slidedeck :: ";

const routes = [
  {
    path: "/",
    name: base_title + "Home",
    component: Empty,
    meta: {
      title: base_title + "Welcome to ..."
    },
  },
  {
    path: "/base/",
    name: base_title + "Base slides ",
    component: Base,
  }

];

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
  routes,
});

export default router;
