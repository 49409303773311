<template>
  <div id="app">
    <!-- specify the link by passing the `to` prop. -->
    <!-- <router-link to="/base">Base slides</router-link> -->
    <!-- route outlet -->
    <!-- component matched by the route will render here -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      reveal: null,
      show: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Mesylab Slidedeck";
      },
    },
  },
};
</script>

<style>
@import url("../node_modules/reveal.js/css/reveal.css");
@import url("./assets/css/reveal-mesydel.css");

#app {
  height: 100vh;
}
</style>
